(function(){
  var σ, parseUrl, last, slice$ = [].slice;
  σ = require('highland');
  parseUrl = require('url').parse;
  last = function(arg$){
    var x;
    x = arg$[arg$.length - 1];
    return x;
  };
  exports.compilePath = function(path){
    var ident, sigil, params, paramReg, reg;
    ident = '[a-z$_][a-z0-9$_]*';
    sigil = ':';
    params = [];
    paramReg = path.replace(RegExp(sigil + '(' + ident + ')', 'ig'), function(m, param){
      params.push(param);
      return '([^\\/]+)';
    });
    reg = (function(){
      switch (false) {
      case path !== '/':
        return /^\/$/;
      case '/' !== last(path):
        return RegExp('^' + paramReg + '?', 'i');
      default:
        return RegExp('^' + paramReg + '\\/?$', 'i');
      }
    }());
    return function(url){
      var pathname, that, route, vals, i, val;
      pathname = parseUrl(url).pathname;
      if ((that = reg.exec(pathname)) != null) {
        route = that[0], vals = slice$.call(that, 1);
        return σ([(function(){
          var i$, ref$, len$, results$ = {};
          for (i$ = 0, len$ = (ref$ = vals).length; i$ < len$; ++i$) {
            i = i$;
            val = ref$[i$];
            results$[params[i]] = val;
          }
          return results$;
        }())]);
      } else {
        return σ([]);
      }
    };
  };
}).call(this);
